import React from "react";

function EmptyStateSVG(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          isolation: "isolate",
        }}
        viewBox="0 0 560 315"
        width="560pt"
        height={420}
        {...props}
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 0h560v315H0z" />
          </clipPath>
        </defs>
        <g clipPath="url(#prefix__a)">
          <path
            d="M204.92 125a5.318 5.318 0 00-4.461 3.637l-31.218 93.821a4.723 4.723 0 00-.163.492l-.164.169a2.379 2.379 0 00-.164.327v.333l-.169.159v.333l-.164.169v.492a5.199 5.199 0 000 2.976v76.806c0 2.907 2.378 5.286 5.286 5.286h232.571c2.907 0 5.286-2.379 5.286-5.286v-77.139a5.362 5.362 0 00-.164-3.632l-.169-.169v-.158a6.527 6.527 0 00-.328-.666l-31.387-94.313A5.28 5.28 0 00374.56 125H204.92zm4.297 10.571h161.537l28.247 84.572h-72.013c-2.907 0-5.285 2.378-5.285 5.286 0 17.569-14.145 31.714-31.715 31.714-17.569 0-31.714-14.145-31.714-31.714 0-2.908-2.379-5.286-5.286-5.286H180.97l28.247-84.572zm-30.229 95.143h69.708c2.722 20.689 19.88 37 41.292 37 21.407 0 38.57-16.311 41.298-37h69.702v68.715h-222v-68.715z"
            fill="yellowgreen"
            opacity={0.64}
          />
          <g opacity={0.64} fill="darkslategrey">
            <path d="M186.307 125.286c-2.343-.261-4.361 1.431-4.621 3.775-.26 2.343 1.432 4.425 3.71 4.686.065 0 9.632 1.171 22.714 8.265 12.041 6.574 29.678 19.721 45.038 45.103.781 1.302 2.213 2.018 3.645 2.018.716 0 1.497-.195 2.213-.586 2.017-1.236 2.668-3.84 1.432-5.792-31.761-52.393-72.439-57.274-74.131-57.469zm207.618 4.23a4.222 4.222 0 00-4.231-4.23c-.52 0-12.366.13-27.27 7.419-13.603 6.639-32.607 20.827-45.689 50.505a4.27 4.27 0 003.84 5.988c1.627 0 3.189-.911 3.905-2.538 9.567-21.803 23.495-37.293 41.263-46.145 13.212-6.508 23.756-6.768 24.016-6.768 2.278 0 4.166-1.888 4.166-4.231zm-90.923-60.268a4.23 4.23 0 00-5.792 1.497c-.26.456-6.899 11.911-11.585 32.738-4.296 19.004-7.615 49.984.521 89.295.39 2.017 2.147 3.384 4.165 3.384.26 0 .586 0 .846-.065 2.278-.456 3.775-2.734 3.254-5.011-7.81-37.619-4.686-67.167-.651-85.326 4.361-19.655 10.674-30.589 10.739-30.719 1.237-2.018.521-4.621-1.497-5.793z" />
            <path d="M167.823 98.471l-2.668-23.365-16.922 16.336-23.04-4.751 10.283 21.152-11.65 20.437 23.3-3.19 15.881 17.378 4.1-23.17 21.413-9.698-20.697-11.129zm135.31-39.636l18.874 14.058-.326-23.56 19.135-13.603-22.454-6.964-7.029-22.389-13.537 19.2-23.561-.26 14.124 18.809-7.485 22.324 22.259-7.615zm152.817 48.943l-19.851-1.237-10.218-16.986-7.224 18.418-19.33 4.491 15.294 12.626-1.757 19.786 16.727-10.674 18.288 7.745-4.946-19.2 13.017-14.969zM217.547 97.625c0 8.135 6.639 14.774 14.774 14.774 8.136 0 14.774-6.639 14.774-14.774 0-8.136-6.638-14.774-14.774-14.774-8.135 0-14.774 6.638-14.774 14.774zm21.738 0a6.97 6.97 0 01-13.928 0 6.971 6.971 0 0113.928 0zm-55.386 72.569c-8.136 0-14.774 6.638-14.774 14.774 0 8.135 6.638 14.774 14.774 14.774 8.135 0 14.774-6.639 14.774-14.774 0-8.201-6.639-14.774-14.774-14.774zm0 21.673a6.97 6.97 0 010-13.928 6.97 6.97 0 010 13.928zM367.24 88.578c8.136 0 14.774-6.638 14.774-14.774 0-8.135-6.638-14.774-14.774-14.774-8.135 0-14.774 6.639-14.774 14.774 0 8.201 6.639 14.774 14.774 14.774zm0-21.673a6.97 6.97 0 010 13.928 6.97 6.97 0 010-13.928zm19.591 117.151c-8.136 0-14.775 6.639-14.775 14.774 0 8.136 6.639 14.775 14.775 14.775 8.135 0 14.774-6.639 14.774-14.775-.04-8.143-6.631-14.734-14.774-14.774zm0 21.673a6.97 6.97 0 010-13.928c3.839 0 6.963 3.124 6.963 6.964-.065 3.84-3.124 6.964-6.963 6.964z" />
          </g>
        </g>
      </svg>
    )
  }
  
  export default EmptyStateSVG