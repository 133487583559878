import React from "react";
import "./slantOverlay.css";
import MailIcon from '@material-ui/icons/Mail';

export default function SlantOverlay ({closeOverlay}) {

    const a = "inf";
    const b = "o@sme";
    const c = "kens-le";
    const d = "gal.be"
    const e = a+b+c+d;


    function handleClose() {
        closeOverlay();
    }

    return <div className="overlay">
              <h1>Slant <span className="exclamationPoint">!</span></h1>
                <div className="flex">
                    <p className="subtitle"><em><b><span className="yellowgreen">S</span>mekens <span className="yellowgreen">L</span>egal  <span className="yellowgreen">an</span>d <span className="yellowgreen">T</span>echnology</b></em></p>
                </div>
                <p align="center"><b>Slant <span className="exclamationPoint">!</span></b> is a boutique legal technology agency.</p>
                <p align="center">To enquire about our services, feel free to send us an email.</p>
                <a align="center" href={`mailto:${e}`}
                    ><MailIcon />
                    Send email
                </a>
                <a align="center" onClick={handleClose}>x Close</a>
          </div>
}