import React, { useEffect } from "react";
import "./welcome.css";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFile';
import KeyboardOutlinedIcon from '@material-ui/icons/Keyboard';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';

export default function Welcome({handleOverlay}) {

  useEffect(()=>{
    window.addEventListener('scroll', function() {
      const button = document.querySelector(".btn");
      if(window.scrollY > 250){
            button.style.visibility="hidden";
          } else if (window.scrollY < 250) {
            button.style.visibility="visible";
      }
  });
  }, []
  )

  function handleOverlayClick () {
    handleOverlay();
  }

  function handleStart () {
    window.scrollTo({
      top: document.getElementsByClassName("welcome")[0].offsetHeight,
      left: 0,
      behavior:"smooth"
    }
      );
  }

  return (
    <div className="welcome">
      <h2>Welcome to</h2>
      <h1>Listo <span className="exclamationPoint">!</span></h1>
      <h2>The <span className="yellowgreen">last hurrah</span> of spreadsheet mailing lists</h2>
      <br/>
      <div className="flex">
        <p className="subtitle pointer" onClick={handleOverlayClick}><em>Powered by <b>Slant <span className="exclamationPoint">!</span></b></em></p>
      </div>
      <button onClick={handleStart} className="btn btn-primary float-right"><span className="cta">Go </span><span className="slanted exclamationPoint">!</span></button>
      <h5>What does Listo <span className="exclamationPoint">!</span> do?</h5>
      <p>
        <b>Listo</b> <span className="exclamationPoint">!</span> is a free, simple and open source letter template builder. 
      </p>
      <p>
        It allows you to automate a mailing from a spreadsheet for one last time, before you reach out to <span className="pointer" onClick={handleOverlayClick}><b>Slant <span className="exclamationPoint">!</span></b></span> to discuss your journey towards digitalisation.
      </p>
      <h5>How does Listo <span className="exclamationPoint">!</span> work?</h5>
      <ul className="list">
        <li>
          <AttachFileOutlinedIcon className="exclamationPoint icon"/> Upload a list of contacts and their addresses in the correct{" "}
          <a href="\assets\UpDoc - Format.xlsx" download>format <OpenInNewIcon fontSize="small"/></a>
        </li>
        <li>
          <KeyboardOutlinedIcon className="exclamationPoint icon"/> Build your template
        </li>
        <li>
          <MarkunreadMailboxOutlinedIcon className="exclamationPoint icon"/> Generate a collection of letters
        </li>
      </ul>
      <p>Then the rest is up to you (fold, stuff, stamp, repeat).</p>
    </div>
  );
}
