import React, { useState, useEffect } from "react";
import ParagraphField from "../paragraphField/paragraphField";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import "./paragraphForm.css";
import {saveAs} from "file-saver";
// PDFMAKE
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as docx from "docx";
// DOCX
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function ParagraphForm({
  content,
  headings,
  topLevelContentLines,
  handleParagraphData, 
  showPreview, 
  handleShowPreview,
  handleGeneratePDF, 
  handleLocation, 
  handleDate, 
  handleSalutationLine, 
  handleNameLine, 
  handleSubjectLine, 
  handleCloseLine, 
  handleCapacityLine,
  letter}) {
  // TO DO
  // Favicon
  // Add BASE64 signature
  // Add sender information
  // Add BASE64 logo / watermark?
  // Add Paragraph in the middle of other paragraphs
  // Make form field component generic
  // Rich text wysiwyg (paid service?)
  // Start removing separate/unnecessary state objects
  // Refactor state into single object/redux/reduce?
  // Undo button after paragraph delete
  // Loading state for generate buttons
  // Refactor pdf gen and docx gen into microservices/modules
  // Address the useEffect warnings (try implementing useCallback hook on parents of DataOverview and ParagraphForm components)
  // Change file input placeholder & button text after upload
  // Implement UseRef hook for scrollTo onClick of FAB
  // Remove references to UpDoc
  // Add extra button: Create Emails

  const [paragraphs, setParagraphs] = useState([{ id: 0, string: "" }]);

  const [location, setLocation] = useState("");

  const [date, setDate] = useState("");
  
  const [salutationLine, setSalutationLine] = useState("");

  const [subjectLine, setSubjectLine] = useState ("");
  
  const [closeLine, setCloseLine] = useState ("");
  
  const [nameLine, setNameLine] = useState ("");

  const [capacityLine, setCapacityLine] = useState ("");

  const [generatePDF, setGeneratePDF] = useState(false);
  
  const [docDefinition, setDocDefinition] = useState(
    {
      content: [
            letter.location+", "+letter.date,
            letter.salutation,
            letter.subject,
            letter.paragraphs.map(e=>e.string),
            letter.close,
            letter.name,
            letter.capacity
    ]
  }
  ); 

  
  useEffect (()=>{
    pdfMake.fonts = {
      Lora: {
        normal: 'https://fonts.gstatic.com/s/lora/v9/aXJ7KVIGcejEy1abawZazg.ttf',
        bold: 'https://fonts.gstatic.com/s/lora/v9/enKND5SfzQKkggBA_VnT1A.ttf'
  
      }
    }
    
    if(topLevelContentLines){
// set PDFmake docdef
      setDocDefinition(
        {
          content: [
            topLevelContentLines.map((el,i)=>{
              
              const body = [
                {text:" ", style:"spacing"},
                {text:letter.location+", "+letter.date, style:"spacing", alignment:"right", margin:[0,0,10,0]},
                {text:letter.salutation, style:"spacing"},
                {text:letter.subject, bold:true, style:"spacing"},
                letter.paragraphs.map((e)=>{
                  return {text:e.string, lineHeight:1.5, margin:[0,0,0,15]}
                }),
                {text:letter.close, style:"spacing"},
                letter.name,
                letter.capacity, 
                i<topLevelContentLines.length-1?{text:" ", pageBreak:"after"}:null
              ]

              el[1].slice().reverse().forEach(line=>{
                body.splice(0,0,{text:line.string, margin:[320,0,0,0]})
              });

              return body
            })
        ],
        pageMargins: [ 80, 60, 80, 60 ],

        defaultStyle: {
          fontSize: 11,
          font: 'Lora'
      },
        styles: {
          spacing: {
            margin: [0,20,0,20]
          }
        }
      }
      );

  }}, [topLevelContentLines, letter]);

  function handleAddParagraph() {
    let temp = [...paragraphs];
    let idArray = [];
    temp.forEach(e=>{
      idArray.push(e.id)
    });
    let biggestId = null;
    if(idArray.length > 0) {
      biggestId = idArray.reduce((a,b)=>{
        return Math.max(a,b);
      })
    } else {
      biggestId = -1;
    }
    let nextId = ++biggestId;
    setParagraphs(paragraphs => [...paragraphs, { id: nextId, string: "" }]);
  }

  function handleParagraph(paragraph, paragraphId) {
    if (paragraphs[0].string === "" && paragraphs.length === 1) {
      setParagraphs([{ id: paragraphId, string: paragraph }]);
    } else if (!paragraphs.every(value => value.id !== paragraphId)) {
      let temp = [...paragraphs];
      let tempIndex = paragraphs.findIndex(
        element => element.id === paragraphId
      );
      temp[tempIndex] = { ...temp[tempIndex], string: paragraph };
      setParagraphs(temp);
    } else {
      setParagraphs(paragraphs => [
        ...paragraphs,
        { id: paragraphId, string: paragraph }
      ]);
    }
  }

  function handleDeleteParagraph(paragraph, paragraphId) {
    let temp = [...paragraphs];
    let tempIndex = paragraphs.findIndex(element => element.id === paragraphId);
    temp.splice(tempIndex, 1);
    setParagraphs(temp);
  }

  useEffect(()=>{
    handleParagraphData(paragraphs);
  }, [paragraphs])

  function handlePreview() {
    handleShowPreview();
  }

  function handlePdfGen() {
    handleGeneratePDF();
    setGeneratePDF(!generatePDF);
    // PDFMAKE
    pdfMake.createPdf(docDefinition).open();
  }

  function handleDocxGen(){
    const address = (element) => new docx.Paragraph({indent:{left:6500}, children: [new docx.TextRun({text:element.string, size:26,})]});
    const locDate = () => new docx.Paragraph({children: [new docx.TextRun({text:letter.location+", "+letter.date,size:26,})], indent:{left:6500}, spacing:{before:800, after:800},});
    const par = (element) => new docx.Paragraph({
      children: [
        new docx.TextRun({
          text: element.string,
          size: 26,
        })
      ],
      spacing:{after:300}
    });
    const doc = new docx.Document ({
        creator:"UpDoc - powered by Smekens Legal & Tech",
        title:"Letter courtesy of UpDoc",
        sections: 
          topLevelContentLines.map((el,i)=>{
            return {
              properties: {},
              children: [
                    ...el[1].map(line=>{
                      return address(line)
                    }),
                    locDate(),
                    new docx.Paragraph({
                      spacing:{before:0, after:800},
                      children: [
                        new docx.TextRun({
                          text: letter.salutation,
                          size:26,
                        })
                      ]
                    }),
                    new docx.Paragraph({
                      spacing:{before:0, after:300},
                      children: [
                        new docx.TextRun({
                          text: letter.subject,
                          bold:true,
                          size:26,
                        })
                      ]
                    }),
                    ...letter.paragraphs.map(e=>{
                      return par(e);
                    }),
                    new docx.Paragraph({
                      spacing:{before:600, after:600},
                      children: [
                        new docx.TextRun({
                          text: letter.close,
                          size:26,
                        })
                      ]
                    }),
                    new docx.Paragraph({
                      children: [
                        new docx.TextRun({
                          text: letter.name,
                          size:26,
                        })
                      ]
                    }),
                    new docx.Paragraph({
                      children: [
                        new docx.TextRun({
                          text: letter.capacity,
                          size:26,
                        })
                      ]
                    }),
                  ]
                }
             })    
          });

    docx.Packer.toBlob(doc).then(blob=>{
      saveAs(blob,"Letter from "+letter.name+".docx");
    });

  }

  function handleLocationChange (event) {
    setLocation(event.target.value, ...location);
  }
  
  function handleDateChange (event) {
    setDate(event.target.value, ...date);
  }
  
  function handleSalutationLineChange (event) {
    setSalutationLine(event.target.value, ...salutationLine);
  }

  function handleSubjectLineChange (event) {
    setSubjectLine(event.target.value, ...subjectLine);
  }
  
  function handleCloseChange (event) {
    setCloseLine(event.target.value, ...closeLine);
  }
  
  function handleNameChange (event) {
    setNameLine(event.target.value, ...nameLine);
  }

  function handleCapacityLineChange (event) {
    setCapacityLine(event.target.value, ...capacityLine);
  }

  function handleLocationBlur () {
    handleLocation(location);
  }
  
  function handleDateBlur () {
    handleDate(date);
  }
  
  function handleSalutationLineBlur () {
    handleSalutationLine(salutationLine);
  }

  function handleSubjectLineBlur () {
    handleSubjectLine(subjectLine);
  }
  
  function handleCloseLineBlur () {
    handleCloseLine(closeLine);
  }
  
  function handleNameLineBlur () {
    handleNameLine(nameLine);
  }

  function handleCapacityLineBlur () {
    handleCapacityLine(capacityLine);
  }

  return (
    <>
      <Form className="form">
        <Form.Group controlId="location">
          <Form.Label>
            Where are you writing from?
          </Form.Label>
          <Form.Control 
            type="text"
            onChange={handleLocationChange}
            onBlur={handleLocationBlur}
            placeholder="Type your location here."
            value={location}
            className="location"/>
        </Form.Group>
        <Form.Group controlId="date">
          <Form.Label>
            On which date are you sending the letter?
          </Form.Label>
          <Form.Control 
            type="text"
            onChange={handleDateChange}
            onBlur={handleDateBlur}
            placeholder="Type the date here in any format you like."
            value={date}
            className="date"/>
        </Form.Group>
        <Form.Group controlId="salutationLine">
          <Form.Label>
            How do you want to address your readers?
          </Form.Label>
          <Form.Control 
            type="text"
            onChange={handleSalutationLineChange}
            onBlur={handleSalutationLineBlur}
            placeholder="Type your salutation here, e.g. 'Dear Sir or Madam,'"
            value={salutationLine}
            className="salutationLine"/>
        </Form.Group>
        <Form.Group controlId="subjectLine">
          <Form.Label>
            Write your letter's subject line here:
          </Form.Label>
          <Form.Control 
            type="text"
            onChange={handleSubjectLineChange}
            onBlur={handleSubjectLineBlur}
            placeholder="Type your subject line here. This will appear as a bold heading in the final document."
            value={subjectLine}
            className="subjectLine"/>
        </Form.Group>
        <Form.Group controlId="paragraphs">
          <Form.Label>Write your letter's body paragraphs here:</Form.Label>
          <Form.Text className="text-muted">
            You can add as many paragraphs as you like, but be careful, <b><span className="listo">Listo</span> <span className="exclamationPoint">!</span>
            doesn't save any data</b>, so be sure to back up your work in a separate
            text file before you close or refresh your browser.
          </Form.Text>
          {paragraphs.map(e => {
            return (
              <ParagraphField
                key={e.id}
                id={e.id}
                handleParagraph={handleParagraph}
                handleDeleteParagraph={handleDeleteParagraph}
                paragraphs={paragraphs}
              />
            );
          })}
        </Form.Group>
        <Button variant="outline-primary" onClick={handleAddParagraph}>
          + Add a paragraph
        </Button>
      <Form.Group controlId="closeLine">
        <Form.Label>
          How do you want to close your letter?
        </Form.Label>
        <Form.Control 
          type="text"
          onChange={handleCloseChange}
          onBlur={handleCloseLineBlur}
          placeholder="Type your close here, e.g. 'Yours truly' or 'Sincerely'."
          value={closeLine}
          className="closeLine"/>
      </Form.Group>
      <Form.Group controlId="nameLine">
        <Form.Label>
          Add your name.
        </Form.Label>
        <Form.Control 
          type="text"
          onChange={handleNameChange}
          onBlur={handleNameLineBlur}
          placeholder="Type your name here."
          value={nameLine}
          className="nameLine"/>
      </Form.Group>
      <Form.Group controlId="capacityLine">
        <Form.Label>
          Finally, add your capacity.
        </Form.Label>
        <Form.Control 
          type="text"
          onChange={handleCapacityLineChange}
          onBlur={handleCapacityLineBlur}
          placeholder="Type your capacity here."
          value={capacityLine}
          className="capacityLine"/>
      </Form.Group>
    </Form>
      <ButtonGroup className="buttongroup">
        <Button
          variant="primary"
          onClick={handlePreview}
          aria-controls="HTMLPreview"
          aria-expanded={showPreview}
        ><VisibilityIcon />
          {showPreview ? " Hide preview" : " Show preview"}
        </Button>
        <Button 
        variant="custom-red"
        onClick={handlePdfGen}
        >
          <PictureAsPdfIcon/> {!generatePDF ? 'Create PDF' : 'Create again'} 
        </Button>
        <Button 
        variant="custom-blue" 
        onClick={handleDocxGen}
        >
          <DescriptionIcon/> Create DOCX
        </Button>
      </ButtonGroup>
    </>
  );
}
