import React, { useState, useEffect } from "react";
import Row from "../row/row";
import "./dataOverview.css";

export default function DataOverview({ headings, content, handleContentLines }) {
  const [contentLines, setContentLines] = useState([]);

  function groupByLine(contentArray, property) {
    return contentArray.reduce((acc, obj) => {
      let key = parseInt(obj[property].substring(1));
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  useEffect(() => {
    setContentLines(groupByLine(content, "id"));
    handleContentLines(groupByLine(content, "id"));
  }, [content]);

  return (<>
      <h3 className="dataOverviewSubtitle">Your address list :</h3>
    <div className="dataOverview container">
      <table>
        <thead>
          <tr>
            {headings.map(e => {
              return <th key={e.id} id={e.id}>{e.string}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {Object.keys(contentLines).map(k => {
            return <Row key={k} cells={contentLines[k]} />;
          })}
        </tbody>
      </table>
    </div>
  </>);
}
