import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import "./paragraphField.css";

export default function ParagraphField({
  handleParagraph,
  handleDeleteParagraph,
  id,
  paragraphs
}) {
  const [paragraph, setParagraph] = useState([]);

  function handleChange(event) {
    setParagraph(event.target.value, ...paragraph);
  }

  function handleBlur() {
    if (typeof paragraph === "string") {
      handleParagraph(paragraph, id);
    }
  }

  function handleDelete() {
    handleDeleteParagraph(paragraph, id);
  }

  return (
    <>
      <Form.Control
        as="textarea"
        rows={5}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Type your content here. This will appear as a paragraph in the final document."
        value={paragraph}
        className="paragraphField"
      />
      {paragraphs.length > 1 && <Button
        className="deletebutton float-right"
        onClick={handleDelete}
        variant="outline-danger"
        size="sm"
      >
        <DeleteOutlinedIcon fontSize="small" /> Delete
      </Button>}
      {paragraphs.length <= 1 && <Button
        className="deletebutton float-right"
        onClick={handleDelete}
        variant="outline-danger"
        size="sm"
        disabled
      >
        <DeleteOutlinedIcon fontSize="small" /> Delete
      </Button>}
    </>
  );
}
