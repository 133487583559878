import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./HTMLPreview.css";

export default function HTMLPreview ({
    headings, 
    content, 
    topLevelParagraphs,
    topLevelLocation,
    topLevelDate, 
    topLevelSalutationLine, 
    topLevelSubjectLine, 
    topLevelCloseLine, 
    topLevelNameLine,
    topLevelCapacityLine}) {
    
const [previewParagraphs, setPreviewParagraphs] = useState();

useEffect(()=>{
    setPreviewParagraphs(topLevelParagraphs);
}, [topLevelParagraphs])
    
    return <>
    <Container className="preview">
        <Row>
            <Col/>
            <Col/>
            <Col/>
            <Col md={{ span: 4, offset: 4 }}>
                {headings && headings.map(e=>{
                    return <p key={e.id}>{e.string} </p>
                })}
            </Col>
        </Row>
        <Row>
            <p></p>
        </Row>
        <Row>
            <p></p>
        </Row>
        <Row>
            <p></p>
        </Row>
        <Row>
        <Col/>
            <Col/>
            <Col/>
            <Col/>
            <Col md={{ span: 4, offset: 4 }}>
                <p>{topLevelLocation}, {topLevelDate}</p>
            </Col>
        </Row>
        <Row>
            <p></p>
        </Row>
        <Row>
            <p>{topLevelSalutationLine}</p>
        </Row>
        <Row>
            <p></p>
        </Row>
        <Row>
            <p><b>{topLevelSubjectLine}</b></p>
        </Row>
        <Row>
            <p></p>
        </Row>
        {previewParagraphs && previewParagraphs.map(e=>{
        return <Row key={e.id}>
                    <p>{e.string}</p>
                </Row>
            })}
        <Row>
            <p></p>
        </Row>
        <Row>
            <p>{topLevelCloseLine}</p>
        </Row>
        <Row>
            <p></p>
        </Row>
        <Row>
            <p>{topLevelNameLine}</p>
        </Row>
        <Row>
            <p>{topLevelCapacityLine}</p>
        </Row>
    </Container>
    </>
}