import './App.css';
import React, { useState, useEffect, useRef } from "react";
import Input from "./components/input/input";
import DataOverview from "./components/dataOverview/dataOverview";
import Welcome from "./components/welcome/welcome";
import ParagraphForm from "./components/paragraphForm/paragraphForm";
import HTMLPreview from "./components/HTMLPreview/HTMLPreview";
import SlantOverlay from "./components/slantOverlay/slantOverlay";
import { Container, Jumbotron } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  const [topLevelData, setTopLevelData] = useState([
    {
      id: "A1",
      string: "Your address data will appear here"
    },
    {
      id: "A2",
      string: "Your address content will appear here"
    },
  ]);

  const [topLevelContentLines, setTopLevelContentLines] = useState();

  const [letter, setLetter] = useState({
    location:"",
    date:"",
    salutation:"",
    subject:"",
    paragraphs:[],
    close:"",
    name:"",
    capacity:""

  })

  const [topLevelParagraphs, setTopLevelParagraphs] = useState([]);

  const [topLevelLocation, setTopLevelLocation] = useState("");

  const [topLevelDate, setTopLevelDate] = useState("");

  const [topLevelSalutationLine, setTopLevelSalutationLine] = useState("");

  const [topLevelSubjectLine, setTopLevelSubjectLine] = useState("");
 
  const [topLevelCloseLine, setTopLevelCloseLine] = useState("");
 
  const [topLevelNameLine, setTopLevelNameLine] = useState("");

  const [topLevelCapacityLine, setTopLevelCapacityLine] = useState("");

  const [headings, setHeadings] = useState([]);

  const [content, setContent] = useState([]);

  const [showPreview, setShowPreview] = useState(false);

  const [generatePDF, setGeneratePDF] = useState(false);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);

  function handleSheetData(sheetData) {
    setTopLevelData(sheetData);
  }

  function handleParagraphData(paragraphData) {
    setTopLevelParagraphs(paragraphData);
    let temp = {...letter};
    temp.paragraphs = paragraphData;
    setLetter(temp);
  }

  const HTMLPreviewRef = useRef();

  function handleShowPreview () {
    setShowPreview(!showPreview);
  }

  function scroll () {
    HTMLPreviewRef.current.scrollIntoView({behaviour:"smooth"});
}

function handleGeneratePDF () {
  setGeneratePDF(!generatePDF);
}

function handleLocation (string) {
  setTopLevelLocation(string)
  let temp = {...letter};
  temp.location = string;
  setLetter(temp);
}

function handleDate (string) {
  setTopLevelDate(string)
  let temp = {...letter};
  temp.date = string;
  setLetter(temp);
  
}

function handleSalutationLine (string) {
  setTopLevelSalutationLine (string);
  let temp = {...letter};
  temp.salutation = string;
  setLetter(temp);
}

function handleSubjectLine (string) {
 setTopLevelSubjectLine(string);
 let temp = {...letter};
 temp.subject = string;
 setLetter(temp);
}

function handleCloseLine (string) {
 setTopLevelCloseLine(string);
 let temp = {...letter};
 temp.close = string;
 setLetter(temp); 
}

function handleNameLine (string) {
  setTopLevelNameLine (string);
  let temp = {...letter};
  temp.name = string;
  setLetter(temp);
}

function handleCapacityLine (string) {
  setTopLevelCapacityLine(string);
  let temp = {...letter};
  temp.capacity = string;
  setLetter(temp);
}

function handleContentLines(obj) {
  setTopLevelContentLines(Object.entries(obj));
}

function handleOverlay() {
  setShowOverlay(!showOverlay);
}

function closeOverlay() {
  setShowOverlay(false);
}

  useEffect(() => {
    let headingsArray = [];
    let contentArray = [];
    topLevelData.forEach(e => {
      if (e.id.length === 2 && e.id.endsWith("1")) {
        headingsArray.push(e);
      } else {
        contentArray.push(e);
      }
    });
    setHeadings(headingsArray);
    setContent(contentArray);
  }, [topLevelData]);

// Loading spinner

const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const el = document.querySelector(".loader-container");
    if(el) {
      el.remove();
      setLoading(false);
    }
  }, [])


  if (loading) {
    return null
  }
  
  return (
    <>
    {showOverlay && <SlantOverlay closeOverlay={closeOverlay}/>}
      <Container fluid className="jumbo">
        <Welcome handleOverlay={handleOverlay} />
      </Container>
      <Container fluid className="formContainer">
        <h1 className="header">
          First, upload your mailing list
        </h1>
        <hr/>
        <h4>
          Be sure to use the correct <a href="\assets\UpDoc - Format.xlsx" download>format</a>
        </h4>
        <Input handleSheetData={handleSheetData} setDataLoaded={setDataLoaded} dataLoaded={dataLoaded}/>
        {dataLoaded && <DataOverview headings={headings} content={content} handleContentLines={handleContentLines}/>}
        {dataLoaded && <h1 className="header">
          Now compose your letter
        </h1>}
        {dataLoaded && <hr/>}
        {dataLoaded && <ParagraphForm 
          handleParagraphData={handleParagraphData}
          handleLocation={handleLocation}
          handleDate={handleDate}
          handleSalutationLine={handleSalutationLine}
          handleSubjectLine={handleSubjectLine} 
          handleCloseLine={handleCloseLine} 
          handleNameLine={handleNameLine}
          handleCapacityLine={handleCapacityLine} 
          handleShowPreview={handleShowPreview} 
          showPreview={showPreview}
          handleGeneratePDF={handleGeneratePDF}
          letter={letter}
          topLevelContentLines={topLevelContentLines}
          headings={headings} 
          content={content}/>}
      </Container>
        {dataLoaded && <Collapse in={showPreview} onEntered={scroll}>
          <Container>
            <div id="HTMLPreview" ref={HTMLPreviewRef} >
              <HTMLPreview 
              topLevelLocation={topLevelLocation}
              topLevelDate={topLevelDate}
              topLevelSalutationLine={topLevelSalutationLine} 
              topLevelSubjectLine={topLevelSubjectLine} 
              topLevelCloseLine={topLevelCloseLine} 
              topLevelNameLine={topLevelNameLine}
              topLevelCapacityLine={topLevelCapacityLine} 
              topLevelParagraphs={topLevelParagraphs} 
              headings={headings} 
              content={content} />
            </div>
          </Container>
        </Collapse>}
    </>
  );
}
// <DraggableList topLevelData={topLevelData} />

