import React from "react";
import "./row.css";
import Cell from "../cell/cell";

export default function Row({ cells }) {
  return (
    <tr>
      {cells.map(c => {
        return <Cell key={c.id} string={c.string} />;
      })}
    </tr>
  );
}
