import React from "react";
import XLSX from "xlsx";
import Form from "react-bootstrap/Form";
import "./input.css";
import EmptyStateSVG from "../emptyStateSVG/emptyStateSVG"

export default function Input({ handleSheetData, setDataLoaded, dataLoaded }) {
  function handleChange(e) {
    let files = e.target.files;
    let file = files[0];
    let reader = new FileReader();
    reader.onload = e => {
      let fileData = new Uint8Array(e.target.result);
      let workbook = XLSX.read(fileData, { type: "array" });
      let sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
      let sheetValues = Object.values(sheet);
      let sheetKeys = Object.keys(sheet);
      let sheetFlattened = [];
      for (let i = 0; i < sheetKeys.length; i++) {
        if (!sheetKeys[i].startsWith("!")) {
          sheetFlattened.push({
            id: sheetKeys[i],
            string: sheetValues[i].v
          });
        }
      }
      handleSheetData(sheetFlattened);
      setDataLoaded(true);
      setTimeout(
        window.scrollTo({
          top: document.getElementsByClassName("welcome")[0].offsetHeight,
          left: 0,
          behavior:"smooth"
        }
          ), 2000
      )
    };
    reader.readAsArrayBuffer(file);
  }

  return (<>
    <Form className="input container">
      <Form.File
        onChange={handleChange}
        id="custom-file"
        label={dataLoaded ? "Something wrong? Upload a different list" : "Click here to upload your .xls mailing list"}
        custom
      />
    </Form>
    {!dataLoaded && <div className="container emptyState">
      <EmptyStateSVG/>
    </div>}
    </>
  );
}
